.nav-item-sub {
  border-left: 1px solid white;
  margin-left: 1em;
  font-size: 0.9em;
}

.nav-item-active {
  background-color: transparent !important;
  border-left-color: var(--primary) !important;
  border-left-width: 2px;
  border-left-style: solid;
}

.app-sidebar {
  min-width: 250px;
  max-width: 250px;
  top: 0;
  color: #fff;
  transition: all 0.6s cubic-bezier(0.945, 0.02, 0.27, 0.665);
  transform-origin: bottom left;
  background-color: rgb(70, 69, 69);
  min-height: 100vh;
}

.app-sidebar-content {
  margin-top: 10vh;
  width: 250px;
}
.app-sidebar-menu {
  margin: 1em 1em;
}
.app-sidebar.active {
  margin-left: -250px;
  transform: rotateY(100deg);
}

.app-sidebar ul.components {
  padding: 0.5em 0;
}

.app-sidebar ul p {
  color: #fff;
  padding: 0.25em;
}

.app-sidebar ul li a {
  padding: 0.5em;
  font-size: 1em;
  display: block;
  color: #fff;
}

.app-sidebar ul li a:hover {
  background: var(--secondary);
  text-decoration-line: none;
}

.app-sidebar ul li.active > a,
a[aria-expanded="true"] {
  color: #fff;
  background: var(--primary);
}

.app-sidebar a[data-toggle="collapse"] {
  position: relative;
}

.app-sidebar .dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 0.5em;
  transform: translateY(-50%);
}

.app-sidebar-collapse {
  width: 1em;
  height: 1em;
  cursor: pointer;
}

.app-sidebar-collapse span {
  width: 80%;
  height: 2px;
  margin: 0 auto;
  display: block;
  transition: all 0.8s cubic-bezier(0.81, -0.33, 0.345, 1.375);
  transition-delay: 0.2s;
}

.app-sidebar-collapse span:first-of-type {
  transform: rotate(45deg) translate(2px, 2px);
}

.app-sidebar-collapse span:nth-of-type(2) {
  opacity: 0;
}

.app-sidebar-collapse span:last-of-type {
  transform: rotate(-45deg) translate(1px, -1px);
}

.app-sidebar-collapse.active span {
  transform: none;
  opacity: 1;
  margin: 5px auto;
}
.app-sidebar-collapse {
  display: none;
}

@media (min-width: 768px) {
  .app-sidebar {
    margin-left: 0;
  }
  .app-sidebar.active {
    margin-left: 0;
    transform: none;
  }
}

@media (max-width: 768px) {
  .app-sidebar {
    margin-left: -250px;
    transform: rotateY(100deg);
  }

  .app-sidebar-collapse {
    display: block;
  }

  .app-sidebar.active {
    margin-left: 0;
    transform: none;
  }

  .app-sidebar-collapse span:first-of-type,
  .app-sidebar-collapse span:nth-of-type(2),
  .app-sidebar-collapse span:last-of-type {
    transform: none;
    opacity: 1;
    margin: 5px auto;
  }

  .app-sidebar-collapse.active span {
    margin: 0 auto;
  }

  .app-sidebar-collapse.active span:first-of-type {
    transform: rotate(45deg) translate(2px, 2px);
  }

  .app-sidebar-collapse.active span:nth-of-type(2) {
    opacity: 0;
  }

  .app-sidebar-collapse.active span:last-of-type {
    transform: rotate(-45deg) translate(1px, -1px);
  }
}
