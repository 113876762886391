.SectionLoader-ripple {
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;

  text-align: center;
}
.SectionLoader-ripple div {
  position: absolute;

  border: 4px solid rgb(212, 71, 16);
  opacity: 1;
  border-radius: 50%;
  animation: SectionLoader-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.SectionLoader-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes SectionLoader-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
