.app-header .navbar-nav .nav-link > a {
  font-size: smaller;
}

.app-header {
  z-index: 3;
  background: url(/img/ronds.svg), linear-gradient(0.15turn, #ff8800, #f40500);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  color: #fff;
  letter-spacing: normal;
  box-shadow: 0 2px 154px 0 rgba(0, 0, 0, 0.1), 0 0 10px 0 rgba(0, 0, 0, 0.15),
    0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.app-header .navbar-brand {
  padding-left: 2em;
}
.app-header .navbar-brand img {
  vertical-align: initial;
  border-right: 1px solid white;
  margin-right: 0.5em;
  padding-right: 0.5em;
  height: 20px;
}

.app-header a {
  color: white;
}
