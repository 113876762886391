body {
  font-size: 16px;
  color: white;
  font-family: "Varela Round", sans-serif;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;

  background-size: 100%;
}
:root {
  --primary: #f40500;
}
