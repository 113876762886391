.App {
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 5s alternate
      cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
}

.App-loader {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background: bottom center no-repeat, linear-gradient(45deg, #f57800, #e30a2f);
}
.App-header {
}
.App-sidebar {
  z-index: 0;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100vh;
  width: 250px;
  background-color: rgb(51, 47, 47);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: scale(0.4);
    opacity: 0.1;
  }
  to {
    transform: scale(0.5);
    opacity: 0.8;
  }
}

@media (min-width: 768px) {
  .App-spliter.App-spliter-right {
    padding-left: 250px;
  }
}

.App-spliter.App-spliter-right {
  z-index: 1;
  width: 100%;
  overflow-x: auto;
}

.App-spliter.App-spliter-left {
  z-index: 2;
  width: 250px;
}

.App-spliter {
  height: 100vh;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  padding-top: 8vh;
}
/* Control the left side */
.App-spliter-left {
  left: 0;
}
/* Control the right side */
.App-spliter-right {
  right: 0;
}
/* If you want the content centered horizontally and vertically */
.App-spliter-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
